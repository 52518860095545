import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "conflict-with-your-teenager-remain-calm"
    }}>{`Conflict with your teenager? Remain calm`}</h1>
    <p>{`Most importantly, how `}<strong parentName="p"><em parentName="strong">{`you`}</em></strong>{` handle conflict is a powerful example to your teenager.`}</p>
    <ul>
      <li parentName="ul">{`Never use physical aggression such as throwing things or slamming doors.`}</li>
      <li parentName="ul">{`Show anger control by slowing down and taking a ‘time out’ to calm down.`}</li>
      <li parentName="ul">{`Make sure you come back calmly and try to resolve the original conflict.`}</li>
    </ul>
    <p>{`For example, when a discussion escalates into an argument, some families have an agreed time-out sign (such as making a “T” with their hands). They might say, “Let’s take a break and cool down”, agree on how long the time-out should be, and come back when the time is up.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m5/24-01.svg" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      